/* eslint-disable @typescript-eslint/no-namespace */

import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/react'
import { CourierProvider } from '@trycourier/react-provider'
import { Toast } from '@trycourier/react-toast'
import { getUnixTime } from 'date-fns'
import { createBrowserHistory } from 'history'
import jwtDecode from 'jwt-decode'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router, useHistory } from 'react-router-dom'

import { get } from '@vori/dashboard-api/get'
import { GlobalErrorBoundary } from '@vori/dashboard-components'
import { GlobalStyles, ToastProvider } from '@vori/gourmet-components'
import { GetJwtDTO } from 'web/dashboard/src/vori-api-client/generated/models/GetJwtDTO'

import {
  UserProvider,
  useCurrentUser,
} from '@vori/dashboard-hooks/useCurrentUser'

import { useFeatureConfig } from '@vori/dashboard-hooks/useFeatureConfig'

import { Routes } from './pages'

import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { FeatureFlags } from './constants'
import { getApolloClient } from './graphql/client'
import MaterialUITheme from './styles/MaterialUITheme'
import { ENV_PROD } from './env'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(quarterOfYear)

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
    crypto: Crypto
    msCrypto: Crypto
    initializeSlope: (data: {
      flow: 'checkout'
      intentSecret: string
      onClose: () => void
      onOrderOpen: () => void
    }) => void
    Slope: { open: () => void }
  }

  namespace JSX {
    interface IntrinsicElements {
      'explo-report-builder': unknown
    }
  }
}

const history = createBrowserHistory()

if (!window.crypto) {
  window.crypto = window.msCrypto
}

const CourierProviderAndRoutes = (): JSX.Element => {
  const history = useHistory()
  const user = useCurrentUser()
  const canSeeCourierInbox = useFeatureConfig(
    FeatureFlags.WEB_NOTIFICATIONS_INBOX,
  )

  // Fetch a JWT granting access to the current user's notifications, via Courier
  const [courierToken, setCourierToken] = React.useState<string | null>(null)
  React.useEffect(() => {
    async function fetchToken() {
      if (!user.state.isLoggedIn || !canSeeCourierInbox) {
        setCourierToken(null)
        return
      }

      try {
        const response = await get<GetJwtDTO>('courier/jwt')
        setCourierToken(response.token)
      } catch (e) {
        if (ENV_PROD) {
          Sentry.captureException(e)
        }
      }
    }

    let intervalLengthInSeconds = 1
    if (courierToken) {
      const decodedToken: { exp: number } = jwtDecode(courierToken)
      intervalLengthInSeconds = decodedToken.exp - getUnixTime(new Date())
    }
    const interval = setInterval(fetchToken, intervalLengthInSeconds * 1000)

    return () => clearInterval(interval)
  }, [canSeeCourierInbox, courierToken, user.state.isLoggedIn])

  return canSeeCourierInbox ? (
    <CourierProvider
      authorization={courierToken || ''}
      // Both the brand id and client key are public keys
      brandId="KCE59F3XN0M9GXH0P16T1GJHH8AH"
      clientKey="MDE0OGNlOTctZDdkZi00OWE4LWFkMmYtNTk2NzgyM2Y2NzEw"
      onRouteChange={(route) => {
        history.push(route)
      }}
      userId={user.data.userSqlID || undefined}
    >
      <Toast position="bottom-right" />
      <Routes enableCourierInbox history={history} />
    </CourierProvider>
  ) : (
    <Routes enableCourierInbox={false} history={history} />
  )
}

const AppWrapper = (): JSX.Element => {
  return (
    <Sentry.ErrorBoundary>
      <GlobalErrorBoundary>
        <Router history={history}>
          <ApolloProvider client={getApolloClient()}>
            <UserProvider>
              <GlobalStyles />
              <ThemeProvider theme={MaterialUITheme}>
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <ToastProvider
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <CourierProviderAndRoutes />
                  </ToastProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </UserProvider>
          </ApolloProvider>
        </Router>
      </GlobalErrorBoundary>
    </Sentry.ErrorBoundary>
  )
}

ReactDOM.render(<AppWrapper />, document.getElementById('root'))
