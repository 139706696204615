import { FormInitialValues } from '@vori/react-form'
import { FormFieldType } from 'web/react-form/src/types/form-field'

export enum OldReportingTab {
  OVERVIEW = 'overview',
  ENDOFDAY = 'end-of-day',
  PROMOTIONS = 'promotions',
  REPORTS = 'reports',
  TILLS = 'tills',
  TRANSACTIONS = 'transactions',
}

export enum ReportingTab {
  SALES = 'sales',
  FINANCES = 'finances',
  INVENTORY = 'inventory',
  POS = 'point-of-sale',
  SHOPPERS = 'shoppers',
}

type ReportingSubsection = {
  label: string
  // If a subsection features a Sigma report, then you MUST define both embedID and workbookID
  embedID?: string
  workbookID?: string
  component?: React.LazyExoticComponent<() => JSX.Element>
}

export const REPORTING_SECTIONS: Record<
  ReportingTab,
  Record<string, ReportingSubsection>
> = {
  [ReportingTab.SALES]: {
    overview: {
      label: 'Sales Overview',
      embedID: '72oC7hKr0AtoTI2dLU67L2',
      workbookID: '4ZcP6m6VuXmvaHqCkrh0KQ',
    },
    trends: {
      label: 'Sales Trends',
      embedID: 'M55VbP8Q5kyhdBo6Hv3Uz',
      workbookID: '2LmW0WBLCXq8T49kBSxeoA',
    },
    'items-sales': {
      label: 'Item Sales',
      embedID: '6AC790P2u5CQRwGp9rNUBS',
      workbookID: '45kytv1wGK0hS58W0jDnOA',
    },
    promotions: {
      label: 'Promotions',
      embedID: '40Oi12R4XxsArTBmhBtey6',
      workbookID: '3hTK2S4kHk5VL5zYGGJYh2',
    },
    'suspended-orders': {
      label: 'Suspended Orders',
      embedID: '1tpNAz4Lu47KVp3NdShurX',
      workbookID: 'KgIyuezuvpJ2ingqfvxNf',
    },
    'department-sales': {
      label: 'Department Sales',
      embedID: '1il6tXGPg6Kb9NXk9MIKL6',
      workbookID: '68JfZZiUOG0QwZoo38EvaA',
    },
    'end-of-month': {
      label: 'End of Month',
      embedID: '5r5wpdAtdLsxVNxe5F5iJs',
      workbookID: '3uhSVIRCG2CZySXXy0WgE8',
    },
  },
  [ReportingTab.FINANCES]: {
    payments: {
      label: 'Payments',
      embedID: '2amnp93cNA3mg0DqdHFiJ6',
      workbookID: '7Kq6izbh3j4oI8HcOyF4eY',
    },
    taxes: {
      label: 'Taxes',
      embedID: '5rX7mEs0b3EPDNslO8Qnaz',
      workbookID: '1b6AiaPVG6m8uz7GWO3UEf',
    },
  },
  [ReportingTab.INVENTORY]: {
    'vendor-report-card': {
      label: 'Vendor Report Card',
      embedID: '55rPuKAkJ1OQhKCEZ7OOyq',
      workbookID: '5KYcWsc6IuYHrzGYiub1Ai',
    },
  },
  [ReportingTab.POS]: {
    'till-trends': {
      label: 'Till Trends',
      embedID: '3O2OOg8onBvPUksZjXHSzs',
      workbookID: '2FmjNjgYNHnWYm7lGgD3xb',
    },
    'till-movements': {
      label: 'Till Pay-Ins & Pay-Outs',
      embedID: '2NABxHlw9q0FLhcmYDxbYv',
      workbookID: '3XqtWVSaW5q7bniFdh8ODe',
    },
    'cashiers-overview': {
      label: 'Cashiers Overview',
      embedID: '4wgrxvjWqvj4dQvuO8cNB9',
      workbookID: '6ng0CA0AOLlbWjzRA3NNKJ',
    },
    'individual-cashier-report': {
      label: 'Cashier Report Card',
      embedID: '3w4ZoYzHrcRglg8o5FF7mt',
      workbookID: '2XbpBPo7nj4EtHIxc1m6dO',
    },
    'manual-items-usage': {
      label: 'Manual Items Usage',
      embedID: 'It88yBXqo6usFdPRrWit3',
      workbookID: '6QupW9NPOEK1hXAhSmsC7u',
    },
    'discounts-usage': {
      label: 'Discounts Usage',
      embedID: '1jWIcLgYRvpVWb9yZUq9QY',
      workbookID: 'A94ia8wtzIZKndK11JJju',
    },
  },
  [ReportingTab.SHOPPERS]: {
    'shopper-trends': {
      label: 'Shopper Trends',
      embedID: 'VeDzU6X1vLhNydBkkbSzM',
      workbookID: '5LZTHlHeyRrM3og1CZdtnd',
    },
    loyalty: {
      label: 'Loyalty',
      embedID: '7dXHxGMo5Oa4f38GgEhKhT',
      workbookID: '3jcQRjU2xujxAKTpKh8ECh',
    },
  },
}

export type ScheduledExportFormSchema = {
  recipient_emails: Extract<FormFieldType, 'text'>
  frequency: Extract<FormFieldType, 'select'>
  send: Extract<FormFieldType, 'select'>
  send_time: Extract<FormFieldType, 'text'>
  timezone: Extract<FormFieldType, 'select'>
}

export enum ScheduleFrequency {
  MONTHLY = 'Monthly',
  WEEKLY = 'Weekly',
  DAILY = 'Daily',
}

export enum ScheduledExportModalMode {
  CREATE,
  LIST,
  UPDATE,
}

export type SelectedScheduledExport =
  FormInitialValues<ScheduledExportFormSchema> & { id: string }
