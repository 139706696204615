import {
  GlobalHTTPHeaders,
  ReducerState,
  UserContextValue,
  UserMetadata,
  UserState,
} from './types'

export function hasError(nextState: ReducerState): boolean {
  return Object.values(nextState.errors).some((error) => error !== null)
}

export function calculateState(
  nextState: ReducerState,
  nextUserState: Partial<UserState>,
): UserState {
  const { isLoading, isLoggedIn, isLoggedOut, ...calculatedState }: UserState =
    {
      ...nextState.state,
      ...nextUserState,
    }

  const nextHasError = hasError(nextState)
  const nextIsLoading = Object.values(calculatedState).some(Boolean)

  const nextIsLoggedIn =
    !nextIsLoading &&
    !nextHasError &&
    !isLoggedOut &&
    Boolean(nextState.data.id)

  return {
    ...calculatedState,
    hasError: nextHasError,
    isLoading: (nextIsLoading || !nextIsLoggedIn) && !isLoggedOut,
    isLoggedIn: nextIsLoggedIn,
    isLoggedOut,
  }
}

export function calculateMetadata(
  state: ReducerState,
  metadata: Partial<UserMetadata>,
): ReducerState {
  const nextState = {
    ...state,
    lastUpdateTimestamp: Date.now(),
    metadata: { ...state.metadata, ...metadata },
  }

  return {
    ...nextState,
    state: calculateState(nextState, {}),
  }
}

export const getDefaultStore = (
  state: UserContextValue,
  httpHeaders?: GlobalHTTPHeaders | null,
) => {
  // TODO: Remove this once we have a better way to determine the default store
  // or have a store selector in the UI
  if (state.data.companyID === 'UwVEiy0LFS4cWAoRihCL') {
    return {
      id: '352',
      name: 'Berkeley Bowl West',
    }
  }
  return {
    id:
      httpHeaders?.['selected-store-sql-id'] ||
      state.entities.stores[0].serialID ||
      state.entities.stores[0].id ||
      null,
    name: state.entities.stores[0].name || null,
  }
}
